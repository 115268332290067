import useLogin from '@/hooks/swr/useLogin'
import { Space } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Button from '../Button'

const FirstPage = () => {
  const router = useRouter()
  const { isLogin } = useLogin()
  return (
    <div className="container flex min-h-screen items-center justify-center">
      <div className="grid h-full w-full grid-cols-1 gap-4 place-self-center lg:grid-cols-5">
        <div className="col-span-1 flex flex-col items-center justify-center gap-16 text-white lg:col-span-2 lg:items-start">
          <div className="text-center lg:text-left">
            <div className="text-3xl font-bold lg:text-5xl">领先的</div>
            <div className="mt-6 text-3xl font-bold lg:text-5xl">赛事综合服务平台</div>
          </div>
          <div className="text-lg opacity-80">
            赛事编排系统、线下赛事数字信息化系统、线上赛事及牌例系统、运动员管理及排名系统、复式发牌机、协码啦效率软件等赛事专用软硬件一应俱全。
          </div>
          <Space size="large">
            <Button
              type="primary"
              onClick={() => router.push(isLogin ? '/match?is_create=1' : '/login?redirect=match?is_create=1')}
              shape="round"
              className="h-[40px] w-[120px] text-base font-semibold sm:h-[50px] sm:w-[200px] sm:text-xl"
            >
              立即办赛
            </Button>
            <Button
              type="link"
              size="large"
              onClick={() => window.open('https://xiemala.com/f/Dsxa1v')}
              className="h-[40px] w-[120px] text-base font-semibold text-white sm:h-[50px] sm:w-[200px] sm:text-xl"
            >
              联系我们
            </Button>
          </Space>
        </div>
        <div className="col-span-1 flex items-center justify-center lg:col-span-3">
          <Image src="/lingti-charactor.png" alt="" className="h-auto w-full object-contain" width={2000} height={1200} />
        </div>
      </div>
    </div>
  )
}
export default FirstPage
